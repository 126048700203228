
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  onBeforeUnmount,
  watch,
} from "vue"
import "@grapecity/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css"
import GC from "@grapecity/spread-sheets"
import {
  initSpread,
  usetableHeight,
  setHeader,
  bindDataTable,
  saveData,
} from "./utils"
import blobDownload from "@/utils/blobDownload"
import { NstGroupProps, SearchParams } from "../types"
import { getNstGroup, saveNstGroupData, confirmNstGroupData, getVersionBymake, getMakeYearList } from "@/API/sysParam"
import { message } from "ant-design-vue"
import { getMonth } from '@/utils'
import moment from 'moment';
// import { NstGroupResources } from "../data"
export default defineComponent({
  name: "",
  setup() {
    // 当前月
    const currentMonth = getMonth();
    const searchParams = reactive<SearchParams>({
      versionValue: "",
      yearValue: 0,
      makeValue: "",
    })

    // 获取表格的高度
    const { tableHeight } = usetableHeight("#ssHost", "#ssHostvp")

    // 设置初始化数据变量
    const dataSource = reactive<NstGroupProps>({
      data: [],
      unConfirmCount: 0,
      version: [],
      year: [],
      make: [],
    })

    // 初始化Spread
    // sheet当前表格的初始化
    let workbook: GC.Spread.Sheets.Workbook
    const ssHotTopInit = () => {
      workbook && workbook.destroy()

      const spread = new GC.Spread.Sheets.Workbook(
        document.getElementById("ssHost") as HTMLElement
      )

      workbook = spread
      const currentYear = +moment().format('YYYY')
      const allYear = currentYear < searchParams.yearValue ? 0 : currentMonth
      initSpread(workbook, allYear)
      setHeader(workbook, allYear)
      // 绑定数据
      bindDataTable(workbook, dataSource.data, allYear)
    }



    // 获取数据
    const initNstGroup = () => {
      const params = {
        make: searchParams.makeValue,
        version: searchParams.versionValue,
        year: searchParams.yearValue,
      }

      // Object.assign(dataSource, NstGroupResources)
      getNstGroup({ params }).then((res) => {
        dataSource.data = res.data
        dataSource.unConfirmCount = res.unConfirmCount
        // 查询条件初始化
        ssHotTopInit()
      })
    }

    // 获取version
    const initVersionData = (params = {}) => {
      getVersionBymake(params).then((res) => {
        dataSource.version = res
        const dataVersion = res.filter(item => item.active)
        searchParams.versionValue = dataVersion[0].value
        initNstGroup()
      })
    }

    const getVersionData = (params = {}) => {
      getVersionBymake(params).then((res) => {
        dataSource.version = res
        const dataVersion = res.filter(item => item.active)
        searchParams.versionValue = dataVersion[0].value
      })
    }

    watch(() => searchParams.makeValue, (value, oldValue) => {
      if (value && oldValue) {
        const params = {
          make: value,
          year: searchParams.yearValue
        }
        getVersionData(params)
      }
    })

    watch(() => searchParams.yearValue, (value, oldValue) => {
      if (value && oldValue) {
        const params = {
          make: searchParams.makeValue,
          year: value
        }
        getVersionData(params)
      }
    })

    const isEmpty = (data: any) => {
        let isSave = true
        data.forEach((item: any)  => {
          if (!item.brand || !item.typeClass) {
            isSave = false
          }
        });
        return isSave
    }

    const saveFn = () => {
      if (saveData.length === 0){
        return
      } else {
        if (!isEmpty(saveData)) {
          message.error('Brand和Type Class不能为空')
          return
        }
      }

      const optionParams = {
        make: searchParams.makeValue,
        version: searchParams.versionValue,
        year: searchParams.yearValue,
      }
      const params = saveData.map((item) => {
        return Object.assign(item, optionParams)

      })
      saveNstGroupData(params).then((res) => {
        message.success("Save successfully!")
        initNstGroup()
      })
    }

    const confirmFn = () => {
      const params = {
        make: searchParams.makeValue,
        version: searchParams.versionValue,
        year: searchParams.yearValue,
      }

      confirmNstGroupData(params).then((res) => {
        message.success("Confirm successfully!")
        initNstGroup()
      })
    }

    const doSearch = () => {
      initNstGroup()
    }

    // 导出表格
    const handlerExport = () => {
      if (searchParams.versionValue && searchParams.yearValue && searchParams.makeValue) {
        const config = {
          url: "/vapi/msrp/nstGroup/export",
          params: {
            make: searchParams.makeValue,
            version: searchParams.versionValue,
            year: searchParams.yearValue,
          },
        }
        blobDownload(config)
      } else {
        message.error("Please select parameters")
      }
    }

    // 获取make year list
    const initYearMakeList = () => {
      getMakeYearList().then(res => {
        dataSource.make = res.make
        dataSource.year = res.year
        // 查询条件初始化
        searchParams.yearValue = searchParams.yearValue || (dataSource.year.length && dataSource.year[0])
        searchParams.makeValue = searchParams.makeValue || dataSource.make[0]
        const params = {
          make: searchParams.makeValue,
          year: searchParams.yearValue
        }
        initVersionData(params)
      })
    }

    onMounted(() => {
      // initNstGroup()
      initYearMakeList()
      // ssHotTopInit()
    })

    onBeforeUnmount(() => {
      workbook.destroy()
    })

    return {
      // table的高度
      tableHeight,
      doSearch,
      saveFn,
      confirmFn,
      // 导出文件
      handlerExport,

      ...toRefs(dataSource),
      searchParams,
    }
  },
})
